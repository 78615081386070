import produce from 'immer';

const INITIAL_STATE = {
  notify: null,
  notifyCoordenadas: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@loadNotifyQuantidadeEstoque': {
        draft.notify = action.payload.notify;
        break;
      }
      case '@loadNotifyCoordenadas': {
        draft.notifyCoordenadas = action.payload.notify;
        break;
      }
      default:
    }
  });
}
