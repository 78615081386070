// Combina reducers em um unico reducers

import { combineReducers} from 'redux';

import auth from './auth/reducer'
import user from './user/reducer'
import notify from './notify/reducer'

export default combineReducers({
  auth,
  user,
  notify
});
