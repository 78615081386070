import {
  takeLatest, call, put, all
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';

import api from '../../../services/api';
import { signFailure, signInSuccess } from './action';
import checkFailAPI from '../../../function/checkFailAPI';

export function signDenied() {
  toast.error('Voce nao tem permissão para acessar esta página');
  history.push('/401');
}

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'session', {
      email,
      password,
    });

    const { token, user } = response.data;

    if (user.grupo.id === 6) {

      yield put(signFailure());
      signDenied();

    } else {

      api.defaults.headers.Authorization = `Bearer ${token}`;
      yield put(signInSuccess(token, user));

      history.push('/dashboard');
    }
  } catch (error) {
    toast.error(checkFailAPI(error.request));
    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/login');
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_DENIED', signDenied),
]);
