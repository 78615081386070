export function notifyQuantidadeEstoqueRequest() {
  return {
    type: '@notifyQuantidadeEstoque/REQUEST',
  };
}
export function loadNotifyQuantidadeEstoque(notify) {
  return {
    type: '@loadNotifyQuantidadeEstoque',
    payload: { notify },
  };
}

export function notifyCoordenadasRequest() {
  return {
    type: '@notifyCoordenadas/REQUEST',
  };
}
export function loadNotifyCoordenadas(notify) {
  return {
    type: '@loadNotifyCoordenadas',
    payload: { notify },
  };
}
