export default function checkFailAPI(request) {
  let error = null;

  switch (request.status) {
    case 401:
      error = 'Falha na autenticação, verifique seus Email e Senha';
      break;
    case 500:
      error = 'Internal Server Error';
      break;
    default:
      error = 'Servidor Back-End Indisponível';
  }

  return error;
}
