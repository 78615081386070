import {
  all, call, put, takeLatest
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import {loadNotifyQuantidadeEstoque } from './action';

export function* notifyEstoqueQuantidade() {
  try {
    const response = yield call(api.get, '/estoquequantidade');

    const newData = response.data.filter((item) => item.status !== 'OK');

    yield put(loadNotifyQuantidadeEstoque(newData));
  } catch (e) {
    toast.error('Falha Consulta Api');
  }
}

export default all([
  takeLatest('@notifyQuantidadeEstoque/REQUEST', notifyEstoqueQuantidade),
]);
