import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { store } from './store';

export default function RouteWrapper({ render: Render, isPrivate, ...rest }) {
  const { signed } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...rest} render={Render} />;
}
