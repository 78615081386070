import React, {Component} from 'react';

import {ToastContainer} from 'react-toastify';

import {PersistGate} from 'redux-persist/integration/react'

// Faz com que toda a aplicação tenha acesso ao store do redux
import {Provider} from 'react-redux'

import {Router, BrowserRouter, Switch} from 'react-router-dom';
import './config/ReactotronConfig';
import Route from './session'
import history from './services/history';
// import { renderRoutes } from 'react-router-config';
import './App.scss';



import {store, persistor} from './store'
import {QueryClientProvider} from "react-query";
import queryClient from "./services/query";
import {ReactQueryDevtools} from "react-query/devtools";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers

// Pages
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Pages/Login'));


class App extends Component {

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <Router history={history}>
                <React.Suspense fallback={loading()}>
                  <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                    <Route path="/" name="Home" isPrivate render={props => <DefaultLayout {...props}/>}/>
                  </Switch>
                </React.Suspense>
              </Router>
              <ToastContainer autoClose={3000}/>
            </BrowserRouter>
          </PersistGate>
        </Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>

    );
  }
}

export default App;
